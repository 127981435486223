<template>
  <div class="download">
    <my-swiper :bannerList="bannerList"></my-swiper>
    <div class="yxj-container">
      <div class="station-container page-container swer">
        <!-- 头 -->
        <div
          class="title-wrapper"
          v-if="!$store.getters.mobile"
        >
          <h1 class="title-label"><span>{{pageObj.title}}</span></h1>
          <p class="sub-title-label"><span>{{pageObj.info}}</span></p>
        </div>
        <!-- 头 -->
        <div
          class="qr-center mobile-qr sm box"
          v-else
        >
          <div class="wx-tips">{{pageObj.title}}</div>
          <div class="wx-label">
            <p>{{pageObj.info}}</p>
          </div>
        </div>
        <ul class="station-wrapper yxj-wrapper yxj-space" @click="toDoctor()">
          <li
            class="item"
            v-for="(item, index) in fadeList"
            :key="index"
          >
            <img
              :src="$store.getters.mobile ? item.mobileImg : item.img"
              :alt="item.title"
              :width="$store.getters.mobile ? '37' : item.width"
              :height="$store.getters.mobile ? '37' : item.height"
            >
            <p>{{item.title}}</p>
          </li>
        </ul>
        <div
          class="btn-wrapper"
          v-if="!$store.getters.mobile"
        >
          <el-popover
            placement="bottom"
            trigger="hover"
            :visible-arrow="false"
          >
            <img
              style="width: 173px;height: 173px"
              src="../../assets/images/down1.png"
            >
            <div
              slot="reference"
              class="btn yxj-btn"
              @click="showDialog(0)"
            >下载app</div>
          </el-popover>
          <el-popover
            placement="bottom"
            trigger="hover"
            :visible-arrow="false"
          >
            <img
              style="width: 173px;height: 173px"
              src="../../assets/images/cgroup_1.jpg"
            >
            <div
              slot="reference"
              class="btn yxj-btn"
              @click="showDialog(1)"
            >关注微信公众号</div>
          </el-popover>
        </div>
        <div v-else>
          <div class="btn-wrapper">
            <a class="btn yxj-btn" href="https://ysz-web.yxj.org.cn/download/index.html#/">下载app</a>
          </div>
          <div class="wechat-com">
            <div class="box">
              <h1>关注微信公众号</h1>
              <h3>
                <span>方法1：</span>
                长按二维码保存本地，打开微信扫一扫-选择相册
              </h3>
              <h3>
                <span>方法2：</span>
                打开微信-添加公众号-搜索「医学界医生站」
              </h3>
              <img class="chat" src="../../assets/images/cgroup_1.jpg" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      class="yxj-wechart-dialog"
      width="369px"
      :modal-append-to-body="false"
      :lock-scroll="false"
      :visible.sync="wechart"
    >
      <img :src="weChartIcon">
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'Download',
  data () {
    return {
      pageObj: {
        title: '医生站',
        info: '立志做中国最好的医生学习和服务平台，为医生提供全临床领域的医学资讯、新药信息、在线CME课程、医学会议、用药查询、医学公式、临床指南、职称考试、会员服务等综合服务。'
      },
      bannerList: [
        {
          to: '/product',
          img: require('@/assets/images/banner01.jpg'),
          mobileImg: require('@/assets/images/banner01m.png'),
        },
        {
          to: '/product',
          img: require('@/assets/images/banner04.jpg'),
          mobileImg: require('@/assets/images/banner04m.png'),
          info: '2015年9月17日，“医生站”APP重磅上线'
        },
        {
          to: '/product',
          img: require('@/assets/images/banner09.jpg'),
          mobileImg: require('@/assets/images/banner09.png'),
          info: '2015年9月17日，“医生站”上线启动仪式'
        }
        // {
        //   to: '/product',
        //   img: require('@/assets/images/banner15.jpg'),
        //   info: '2015年9月17日，“医生站”上线发布会召开'
        // }
      ],
      fadeList: [
        {
          img: require('@/assets/images/i_01.png'),
          mobileImg: require('@/assets/images/mobile_i1.png'),
          title: '医学资讯',
          width: 27,
          height: 29
        },
        {
          img: require('@/assets/images/i_02.png'),
          mobileImg: require('@/assets/images/mobile_i2.png'),
          title: '新药信息',
          width: 21,
          height: 27
        },
        {
          img: require('@/assets/images/i_03.png'),
          mobileImg: require('@/assets/images/mobile_i3.png'),
          title: '在线CME',
          width: 30,
          height: 30
        },
        {
          img: require('@/assets/images/i_04.png'),
          mobileImg: require('@/assets/images/mobile_i4.png'),
          title: '用药查询',
          width: 32,
          height: 28
        },
        {
          img: require('@/assets/images/i_05.png'),
          mobileImg: require('@/assets/images/mobile_i5.png'),
          title: '临床指南',
          width: 31,
          height: 32
        },
        {
          img: require('@/assets/images/i_06.png'),
          mobileImg: require('@/assets/images/mobile_i6.png'),
          title: '医学公式',
          width: 26,
          height: 29
        },
        {
          img: require('@/assets/images/i_07.png'),
          mobileImg: require('@/assets/images/mobile_i7.png'),
          title: '医学会议',
          width: 33,
          height: 31
        },
        {
          img: require('@/assets/images/i_08.png'),
          mobileImg: require('@/assets/images/mobile_i8.png'),
          title: '职称考试',
          width: 25,
          height: 26
        },
        {
          img: require('@/assets/images/i_09.png'),
          mobileImg: require('@/assets/images/mobile_i9.png'),
          title: '会员服务',
          width: 42,
          height: 32
        }
      ],
      wechart: false,
      weChartIcon: ''
    }
  },
  computed: {
  },
  created () { },
  methods: {
    showDialog (id) {
      this.wechart = true
      this.weChartIcon = id === 0 ? require('@/assets/images/down1.png') : require('@/assets/images/cgroup_1.jpg')
    },
    toDoctor(){
      window.location.href = 'https://web.yishengzhan.cn/#/home'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
